import * as React from 'react';
import { graphql, navigate, PageProps } from 'gatsby';
import { PageContext } from '../types/pageContext';
import ContentfulImage from '../components/contentful/ContentfulImage';
import { FormEvent, useContext, useRef } from 'react';
import ContentfulLink from '../components/contentful/ContentfulLink';
import { GlobalStateContext } from '../hooks/globalStateContext';

export const query = graphql`
  query NotFoundPage {
    contentfulGlobalOptions {
      ...GlobalFragment
      pageNotFoundBackgroundImage {
        ...AssetFragment
        gatsbyImageData
      }
    }
    contentfulComponentSiteHeader {
      logo {
        ...AssetFragment
      }
      userNavigation {
        ...NavigationFragment
      }
      mainNavigation {
        ...NavigationFragment
      }
      searchCta {
        ...LinkFragment
      }
    }
    contentfulComponentSiteFooter {
      exclusiveToRetailer {
        ...ContentFlexibleFragment
      }
      legalCopy {
        ...ContentFlexibleFragment
      }
      logo {
        ...AssetFragment
      }
      menus {
        ...NavigationFragment
      }
      signUpForm {
        ...FormFragment
      }
      socials {
        ...NavigationFragment
      }
    }
  }
`;
const NotFoundPage: React.FC<
  PageProps<Queries.NotFoundPageQuery, PageContext>
> = (props) => {
  const { data } = props;
  const { contentfulGlobalOptions, contentfulComponentSiteHeader } = data;
  const inputRef = useRef<HTMLInputElement>(null);
  const { microcopy } = useContext(GlobalStateContext);

  const onSubmitSearchForm = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (inputRef.current?.value) {
      navigate(`/search?q=${inputRef.current.value}`);
    }
  };

  return (
    <section className={`grid grid-cols-1`}>
      {contentfulGlobalOptions?.pageNotFoundBackgroundImage?.[0] && (
        <div
          className={`row-start-1 col-start-1 max-h-[800px] overflow-hidden`}
        >
          <ContentfulImage
            image={contentfulGlobalOptions.pageNotFoundBackgroundImage[0]}
            className={`w-full h-full`}
          />
        </div>
      )}
      <div
        className={`row-start-1 col-start-1 relative py-lg grid place-content-center text-white`}
      >
        <div className={`container tablet:max-w-[520px] mx-auto`}>
          {microcopy?.PageNotFoundTitle && (
            <h2 className={`type-h2 uppercase`}>
              {microcopy?.PageNotFoundTitle}
            </h2>
          )}
          {contentfulComponentSiteHeader?.logo?.[0] && (
            <ContentfulLink link="/">
              <ContentfulImage
                image={contentfulComponentSiteHeader.logo[0]}
                className={`mt-xs`}
              />
            </ContentfulLink>
          )}
          {microcopy?.PageNotFoundBody && (
            <p className={`mt-xs`}>{microcopy?.PageNotFoundBody}</p>
          )}
          <form
            onSubmit={onSubmitSearchForm}
            className={`mt-xs flex flex-col tablet:flex-row`}
          >
            <input
              ref={inputRef}
              onChange={(e) => {
                if (inputRef.current) {
                  inputRef.current.value = e.target.value;
                }
              }}
              type="search"
              className={`tablet:rounded-r-none`}
            />
            <button
              className={`btn-secondary uppercase mt-xxs tablet:mt-0 tablet:border-l-0 tablet:rounded-l-none`}
            >
              {microcopy?.Search ?? 'Search'}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default NotFoundPage;
